var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-table",
        { attrs: { data: _vm.logs, height: "400px" } },
        [
          _c("el-table-column", {
            attrs: { prop: "message", label: _vm.$t("dashboard.message") }
          }),
          _c("el-table-column", {
            attrs: { prop: "date", label: _vm.$t("dashboard.date") }
          }),
          _c("el-table-column", {
            attrs: { prop: "event", label: _vm.$t("dashboard.event") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: { type: _vm.filterStyleClass(scope.row.event) }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.events.find(function(event) {
                              return event.value == scope.row.event
                            }).text
                          )
                        )
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }