var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "summary" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ]
        },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("dashboard.general-info")))
            ])
          ]),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.$store.getters.generalInfo,
                width: "100%",
                "show-header": _vm.showHeaders,
                "max-height": _vm.height
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "title", label: "Başlık", width: "350px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t(scope.row.title)))
                        ]),
                        _c("span", [_vm._v(":")])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "value", label: "Değer" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }