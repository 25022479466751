<template>
  <div class="statistics">
    <el-row>
      <el-col>
        <app-navigation :title="$t('dashboard')"></app-navigation>
      </el-col>
    </el-row>
  <el-row style="margin-top: 30px; margin-bottom: 10px; display: flex; justify-content: space-around;">
    <el-col :span="3" v-for="(stat, index) in stats" :key="'stat-' + index" class="container">
      <div class="icon-container">
          <span>
            <img class="dashboard-icon" :src=getImgPath(stat.icon)>
          </span>
        </div>
      <div style="cursor: pointer; margin: 1rem;" @mouseover="stat.mouseOver = true" @mouseout="stat.mouseOver = false"
        @click="$router.push({ name: stat.router })">
        <div style="display: flex; flex-direction: column; justify-content: flex-start;" v-loading="stat.loading">
          <span class="value">
            {{ stat.value }}
          </span>
          <span class="stat-title">
            {{ stat.title }}
          </span>
        </div>
      </div>
    </el-col>
    <el-col v-if="index < stats.length - 1" :span="1">
      <div class="line"></div>
    </el-col>
  </el-row>
</div>
</template>

<script>
import { mapGetters } from "vuex"
import Navigation from "@/components/Navigation.vue";

export default {
  name: "Statistics",
  components: {
    "app-navigation": Navigation,
  },

  data() {
    return {
      stats: [
        { title: this.$t('dashboard.drivers'), icon: "driver", value: 0, mouseOver: false, loading: false, onload: () => this.stats[0].value = this.driverCount, method: "getDrivers", router: "Drivers" },
        { title: this.$t('dashboard.accessories'), icon: "accessories", value: 0, mouseOver: false, loading: false, onload: () => this.stats[1].value = this.accessoryCount, method: "getAccessories", router: "Accessories" },
        { title: this.$t('dashboard.rooms'), icon: "rooms", value: 0, mouseOver: false, loading: false, onload: () => this.stats[2].value = this.roomCount, method: "getRooms", router: "Rooms" },
        { title: this.$t('dashboard.contacts'), icon: "intercom-contacts", value: 0, mouseOver: false, loading: false, onload: () => this.stats[3].value = this.contactCount, method: "getContacts", router: "Contacts" },
        { title: this.$t('dashboard.scenes'), icon: "scenes", value: 0, mouseOver: false, loading: false, onload: () => this.stats[4].value = this.sceneCount, method: "getScenes", router: "Scenes" },
        { title: this.$t('dashboard.cameras'), icon: "ip-cameras", value: 0, mouseOver: false, loading: false, onload: () => this.stats[5].value = this.ipcameraCount, method: "getIPCameras", router: "IPCameras" },
      ],
    }
  },

  computed: {
    isConnected() {
      return this.$store.getters.isConnected;
    },
    ...mapGetters([
      "driverCount", "accessoryCount", "sceneCount", "contactCount", "roomCount", "ipcameraCount"
    ])
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    getImgPath: function(icon) {
      return require(`../../../assets/icons/` + icon + `.png`);
    },
    async fetchData() {
      this.stats.forEach(stat => {
        stat.loading = true
        this.$store.dispatch(stat.method).then(message => {
          stat.onload()
          stat.loading = false
        }).catch(err => stat.loading = false)
      })
    }
  },


  created() {

    if (this.isConnected)
      this.fetchData();
  }
}
</script>

<style>
@import '../../../style/statistics.css';
</style>