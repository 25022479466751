<template>
  <el-card>
    <el-table :data="logs" height="400px">

      <el-table-column
              prop="message"
              :label="$t('dashboard.message')">

      </el-table-column>

      <el-table-column
              prop="date"
              :label="$t('dashboard.date')">
      </el-table-column>

      <el-table-column
              prop="event"
              :label="$t('dashboard.event')">

        <template slot-scope="scope">
          <el-tag :type="filterStyleClass(scope.row.event)">{{ events.find(event => event.value == scope.row.event).text }}</el-tag>
        </template>

      </el-table-column>

    </el-table>
  </el-card>
</template>

<script>
  export default {
    name: "LogsWidget",

    data: function () {
      return {
        logs: [
          {
            message: "Unable to access the CoreCenter driver",
            date: "18.12.2018 13:30",
            event: 3
          },
          {
            message: "The CoreCenter drive is accessible again",
            date: "18.12.2018 13:30",
            event: 2
          },
          {
            message: "Violation occurred: Smoke Sensor",
            date: "18.12.2018 13:30",
            event: 1
          },
          {
            message: "Lighting switched on: Lambader",
            date: "18.12.2018 13:30",
            event: 4
          },
          {
            message: "Violation ended: Smoke Sensor",
            date: "18.12.2018 13:30",
            event: 2
          },
          {
            message: "(2) Missed Call",
            date: "18.12.2018 13:30",
            event: 3
          }
        ],

        events: [
          { text: "Breach", value: 1 },
          { text: "Success", value: 2 },
          { text: "Failure", value: 3 },
          { text: "Trigger", value: 4 },
          { text: "Warning", value: 5 },
        ]
      }
    },

    methods: {
      filterStyleClass(event){
        switch (event) {
          case 1:
            return "danger"
          case 3:
            return "warning"
          case 4:
            return "info"
          case 2:
            return "success"
          default:
            return ""
        }
      },
    }
  }
</script>

<style scoped>
  .el-table {
    font-size: 12px;
  }
</style>