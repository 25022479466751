<template>
<div class="summary">
  <el-card v-loading="loading">
    <div slot="header">
      <span class="title">{{ $t('dashboard.general-info') }}</span>
    </div>
      <el-table :data="$store.getters.generalInfo" width="100%" :show-header="showHeaders" :max-height="height" >
        <el-table-column prop="title" label="Başlık" width="350px">
          <template slot-scope="scope">
            <div class="title">{{ $t(scope.row.title) }}</div>
            <span>:</span>
          </template>
        </el-table-column>
        <el-table-column prop="value" label="Değer"></el-table-column>
      </el-table>
  </el-card>
</div>
</template>

<script>
export default {
  name: "Summary",
  data: function() {
    return {
      height: 750,
      showHeaders: false,
      loading: false,
    };
  },

  computed: {
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    isConnected(value) {
      if (value) {
        this.$store.dispatch("getGeneralInfo");
      }
    },
  },

  methods: {
    fetchData: async function() {
      this.loading = true;
      await this.$store.dispatch("getGeneralInfo");
      this.loading = false;
    },
  },

  created() {
    if (this.isConnected) this.fetchData();
  },
};
</script>

<style scoped>
@import '../../../style/summary.css';

</style>
