<template>
  <div>
    <el-row>
      <el-col :span="24">
        <app-statistics></app-statistics>
      </el-col>
    </el-row>


    <el-row style="margin-top: 20px" :gutter="20">
      <el-col :span="24">
        <app-summary></app-summary>
      </el-col>

      <el-col v-show="false" :span="12">
        <logs-widget></logs-widget>
      </el-col>

    </el-row>

  </div>
</template>

<script>
  import Summary from "./components/Summary"
  import LogsWidget from "./components/LogsWidget"
  import Statistics from "./components/Statistics"

  export default {
    name: "index",
    components: {
      logsWidget: LogsWidget,
      'app-summary': Summary,
      appStatistics: Statistics
    },

    methods: {
      routeToDrivers(){
        this.$router.push({name: 'Drivers'})
      }
    },
  }
</script>

<style scoped>

</style>