var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "statistics" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [_c("app-navigation", { attrs: { title: _vm.$t("dashboard") } })],
            1
          )
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: {
            "margin-top": "30px",
            "margin-bottom": "10px",
            display: "flex",
            "justify-content": "space-around"
          }
        },
        [
          _vm._l(_vm.stats, function(stat, index) {
            return _c(
              "el-col",
              {
                key: "stat-" + index,
                staticClass: "container",
                attrs: { span: 3 }
              },
              [
                _c("div", { staticClass: "icon-container" }, [
                  _c("span", [
                    _c("img", {
                      staticClass: "dashboard-icon",
                      attrs: { src: _vm.getImgPath(stat.icon) }
                    })
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticStyle: { cursor: "pointer", margin: "1rem" },
                    on: {
                      mouseover: function($event) {
                        stat.mouseOver = true
                      },
                      mouseout: function($event) {
                        stat.mouseOver = false
                      },
                      click: function($event) {
                        return _vm.$router.push({ name: stat.router })
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: stat.loading,
                            expression: "stat.loading"
                          }
                        ],
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "justify-content": "flex-start"
                        }
                      },
                      [
                        _c("span", { staticClass: "value" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(stat.value) +
                              "\n          "
                          )
                        ]),
                        _c("span", { staticClass: "stat-title" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(stat.title) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ]
                )
              ]
            )
          }),
          _vm.index < _vm.stats.length - 1
            ? _c("el-col", { attrs: { span: 1 } }, [
                _c("div", { staticClass: "line" })
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }